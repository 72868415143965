.text-page {
  margin: 18vh 10vh 5vh 10vh;
  color: white;
  
  h1 {
    word-break: break-word;
    font-family: 'Poppins';
    text-align: center;
    font-size: 2rem;
  }

  h2 {
    text-align: center;
  }

  a {
    color: #a7a7a7;
  }

  .content {
    margin-top: 5%;
    word-break: break-word;

  }
  
  p.section { 
    padding-top:40px;
  }
}

@media screen and (max-height: $screen-s){
  .text-page.ismobile{
    margin: 2vh;
  }
}

@media screen and (max-height: $screen-m){
  .text-page.ismobile{
    height: auto;
    margin: 2vh;
  }
}

@media screen and (max-height: $screen-l){
  .text-page.ismobile{
    height: auto;
    margin: 12vh 8vh 0vh 8vh;
  }
}

.loader{
  width: 100%;
  height: 100%;
  background-color: $secondary;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;

  $thumb-height: 30px;
  $thumb-width: 50px;
  $bar-width: 200px;

  .cover{
    height: 100%;
    width: 100%;

    position: relative;
    z-index: 102;

    .tag{
      color: $secondary;

      position: relative;
      left: 50%;
      top: 50%;
      text-align: center;

      transform: translate(-50%, -50%);
    }

    &::before{
      content: '';
      width: 100%;
      height: 100%;
      background-color: black;

      position: absolute;
      top: 0;
      bottom: 0;

      width: 0;
      left: 0;
      transition: all 1s ease;
      transition-property: width, left;
    }

    &::after{
      content: '';

    }

    .message{
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 1rem;
      width: 90%;
      text-align: center;

      opacity: 0;
    }

  }

  .content{
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: fit-content;
    height: 30px!important;
    overflow: hidden;
    display: flex;

    height: 50px;
    padding-top: 20px;
    background-color: $secondary;

    &::before{
      // block cover 'humancontact inc'
      content: '';
      width: 100%;
      height: 40px;
      background-color: $secondary;
      top: 100%;
      bottom: 0;
      position: absolute;
      z-index: 101;
    }

    .bar{

      margin-top: 4px;
      margin-right: 10px;

      width: $bar-width;
      height: 1px;

      position: relative;

      &::before,
      &::after{
        content: '';
        width: 0;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }

      &::before{
        background-color: $secondary;
        z-index: 102;
        transition: width 0.6s ease;
      }

      &::after{
        background-color: white;
        animation: loadbar 1.6s 0.6s ease forwards;
      }
    }

    .thumb{
      width: $thumb-width;
      min-width: $thumb-width;
      height: $thumb-height;
      margin-right: 8px;
      transform: translateY(-40%);

      opacity: 0;
      animation: thumbfadein 0.4s 0.25s forwards;

      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    h4{
      color: white;
      font-weight: 100;
      text-transform: uppercase;
      font-size: 14px;
      text-align: left;

      margin: 0;
      margin-top: -4px;
      overflow: hidden;

      min-width: $bar-width;
      transform: translateY(40px);
      animation: loadtext 0.4s ease 1s forwards;
    }
  }

  &.start{

    .cover{
      &::before{
        width: 100%;
        transition-delay: 0.4s;
      }

      .thumb{
        opacity: 1;
      }
    }
    .content .bar::before{ width: 100%; }
  }
}

@media screen and (max-width: $screen-s){
  .loader{
    .content{
      & .bar,
      & .thumb{
        display: none;
      }

      h4{
        text-align: center;
      }
    }
  }
}

@keyframes loadbar{
  0%{ width: 0; }
  100%{ width: 100%; }
}

@keyframes loadbarout{
  0%{ width: 100%; }
  100%{ width: 0%; }
}

@keyframes loadtext{
  0%{
    opacity: 0;
    transform: translateY(40px);
  }
  100%{
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes thumbfadein{
  0%{ opacity: 0; }
  100%{ opacity: 1; }
}

@keyframes translateUp{
  0%{ transform: translateY(30px); }
  100%{ transform: translateY(0px); }
}

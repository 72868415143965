$team-photo-container-width: 50vw;
$photo-size: 320px;
$photo-size-large: 420px;
$photo-size-small: 100vw;

.description-content-container{
  text-align: right;
  margin: 0 20px;
  padding-left: 20px;
  position: relative;

  h2{

    position: relative;

    background-color: $primary;
    width: fit-content;
    padding: 10px 20px;
    margin-left: auto;

    font-size: 14px;
    text-transform: uppercase;
    font-family: Poppins;
    font-weight: 600;

    color: $secondary;

    &::before{
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 0;
      height: 100%;
      background-color: $primary;

      transition: width 0.25s ease;
    }
  }

  h1{
    margin: 20px 0;
    font-family: 'Poppins';
    font-size: 1.8rem;
    font-weight: 600;
  }

  .summary{
    margin: 20px 0;

    font-family: Poppins;
    font-size: 0.9rem;
    font-weight: 100;
    color: white;
  }

  .readmore{
    font-family: ProximaNova;
  }
}

.teamcontainer{
  color: white;
  background-color: $secondary;

  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;

  $content-offsetY: calc(50vh - 200px);
  $bar-width: 2px;

  *{ user-select: none; }

  .navigation{
    position: absolute;
    top: 50%;
    left: 6%;
    transform: translateY(-50%);

    height: 150px;
    width: 2px;
    background-color: rgba(255,255,255,0.2);

    #progress{
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 0%;
      background-color: rgba(255,255,255,0.8);
    }

    h4{
      position: absolute;
      margin: 0;
      font-family: 'Poppins';
      font-weight: 400;

      &:nth-child(1){
        top: 0;
        transform: translate(-50%, -160%);
      }

      &:nth-child(2){
        bottom: 0;
        transform: translate(-50%, 160%);
      }
    }

    #chevrons{
      height: 100%;
      position: absolute;

      div{
        position: absolute;
        opacity: 0.25;
        transition: opacity 0.2s ease;

        &:active{
          opacity: 0.5;
          transition: opacity 0s ease;
        }

        &:hover{
          opacity: 0.6;
        }

        &:nth-child(1){
          top: -40px;
          transform: translate(-50%, -100%);
        }

        &:nth-child(2){
          bottom: -45px;
          transform: translate(-50%, 100%);
        }
      }

    }

  }

  #mobile-humans-navigator{
    position: absolute;
    top: 18%;
    right: 20px;

    display: grid;
    grid-template-columns: 1fr 1fr;

    display: none;

    #mobileaboutthishuman{
      border-bottom: 2px solid;
      font-size: 0.75rem;
      outline: none;
      height: fit-content;
      width: fit-content;
    }

    #mobilenexthuman{
      margin-left: 10px;
      display: flex;

      div:active{ opacity: 0.6; }

    }

  }

  .descriptioncontainer{
    position: absolute;
    top: $content-offsetY;

    display: flex;
    width: $team-photo-container-width;
    max-width: 380px;

    left: 50%;
    transform: translateX(-100%);
    padding-right: 20px;

    *{ color: white; }

    .descriptions{
      @extend .description-content-container;

      margin-left: auto;

      .summary, h1, h2{
        opacity: 0;
        transform: translateY(20px);
      }
    }

    .photos{

      width: 50vw;
      height: 100vh;
      transform-origin: top;

      .item{

        height: 100vh;
        width: 50vw;
        position: relative;

        img{
          width: $photo-size;
          height: $photo-size;
          object-fit: cover;

          transition: transform 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
        }

        .descriptioncontainer{
          @extend .description-content-container;
        }
      }

    }
  }

  .grid-toggler{

    position: absolute;
    bottom: 60px;
    left: calc(50vw - 40px);
    transform: translateX(-100%);

    display: flex;
    opacity: 0;

    span{
      width: 80px;
      text-align: right;
      margin-right: 10px;
      margin-top: 10px;
      opacity: 0.25;

      line-height: 20px;
    }

    .tagline{
      $square-container-size: 40px;

      font-family: HelveticaNeueThin;
      font-size: 10px;

      width: $square-container-size;
      height: $square-container-size;
      margin-left: auto;
      margin-top: 10px;

      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;

      &.active div{ background-color: rgba(255,255,255,0.2); }

      div{
        margin: 2px;

        background-color: $primary;

        &.active{ background-color: $primary; }
      }

    }
  }

  .portraits{
    $portrait-padding: 0vh;
    position: absolute;
    left: 50vw;
    width: calc(50vw - #{$portrait-padding}/2);
    padding: 0;
    height: calc(100vh - #{$portrait-padding} );
    top: calc(#{$portrait-padding} / 2);
    background-color: white;

    overflow: hidden;
    opacity: 0;

    .photos{
      top: 0;
      background-color: $secondary;
      width: 50vw;
      height: 100vh;

      &::-webkit-scrollbar{ display: none; }

      .item{
        height: 100%;
        width: 100%;

        .descriptioncontainer{
          display: none;
          left: 0px;
          top: 0;
          width: 100%;
          pointer-events: none;

          transform: translateY(20px);
          opacity: 0;
          transition: all 0.4s ease;

          *{ height: auto; }

          .descriptions{
            top: 100%;
            transform: translateY(-120%);
          }
        }

        &:hover{
          .descriptioncontainer{
            opacity: 1;
            transform: translateY(0);
          }
        }

      }
    }

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      transition: opacity 0.2s ease;
    }

    .cover{
      position: absolute;
      width: 140%;
      height: 140%;
      left: -10%;
      top: -10%;
      z-index: 1;
      pointer-events: none;
      background-color: rgba(0,0,0,0.25);
      backdrop-filter: blur(40px);
      transition: all 0.6s ease;
      transition-property: backdrop-filter, background-color;

      &.reveal{
        backdrop-filter: blur(0px);
        background-color: rgba(16,16,16,0);
      }
    }

    .teamgrid{

      height: 100vh;
      overflow: scroll;
      background-color: black;

      .scroll-content{
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
      .scrollbar-track{ display: none!important; }

      .item{
        display: flex;
        opacity: 1;
        position: relative;
        transition: filter 0.1s ease-in-out;

        &::before{
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0,0,0,0.65);
          transition: opacity 0.6s ease;
        }

        &:hover::before{
          opacity: 0;
        }

        &.active::before{
          opacity: 0;
        }

        .details{
          opacity: 0;
          pointer-events: none;
          transition: opacity 0.2s ease;

          top: 50%;
          transform: translateY(-50%);

          position: absolute;
          padding: 5%;

          .descriptions{
            text-align: right;
            margin: 0 20px;
            position: relative;

            h2{

              position: relative;

              background-color: $primary;
              display: table; // edge width
              width: fit-content;
              padding: 10px 20px;
              margin-left: auto;

              font-size: 14px;
              text-transform: uppercase;
              font-family: Poppins;
              font-weight: 500;

              color: $secondary;

              &::before{
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 0;
                height: 100%;
                background-color: $primary;

                transition: width 0.25s ease;
              }
            }

            h1{
              margin: 20px 0;
              font-family: 'Poppins';
              font-size: 1.8rem;
              font-weight: 600;
            }

            .summary{
              margin: 20px 0;

              font-family: ProximaNova;
              font-size: 1rem;
              color: white;
            }

          }
        }

      }
    }

  }

  .slick-track{
    left: 0;
    position: relative;
    display: flex;

    *{
      outline: none;
      user-select: none;
    }

    .slick-slide{
      transform: translateY(40px);
      opacity: 0;
    }

    img{ height: 100vh!important; }
  }

  .photonavigation{
    position: absolute;
    top: calc(#{$content-offsetY} + 40px);
    right: 40px;

    height: calc(#{$photo-size} - 80px);
    width: 10px;

    padding-left: 3px;

    & .bar1{
      background-color: #98989821;
      height: 100%;
      width: $bar-width;
      overflow: hidden;

      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      & .bar2{
        position: absolute;
        top: 0;
        transform: translateY(-50%);

        width: $bar-width;
        height: 20%;

        background-color: $primary;

        transition: top 0.4s ease;
      }
    }

    & .personindex,
    & .personindexall{
      position: absolute;
      font-family: HelveticaNeueLight;
      color: $secondary;
    }

    & .personindexall{ transform: translateX(-50%); }

    & .personindex{
      top: -18%;
      display: flex;
      overflow: hidden;
      transform: translateX(-50%);

      letter-spacing: 1px;

      & span{
        display: block;
        transform: translateY(0px);
        transition: all 0.3s ease;

        &:nth-child(1){ margin-right: 1px; }
        &:nth-child(2){ transition-delay: 0.1s; }
      }

      &.hide span{
        transform: translateY(-20px);
        opacity: 0.5;
      }
      &.show span{
        transform: translateY(20px);
        transition: all 0.3s ease;
      }
    }

    & .personindexall{ bottom: -18%; }

  }
}

@media screen and (max-width: $screen-l){
  .teamcontainer{
    $slider-width-tablet: 65%;

    min-height: 100vh;

    .descriptioncontainer{
      z-index: 1;

      .descriptions{
        padding-left: 40px;
      }
    }

    .portraits{

      width: $slider-width-tablet;
      height: 80vh;
      max-height: 600px;
      position: absolute;
      right: 0;
      left: unset;
      top: 50%;
      transform: translateY(-45%);

      .photos{
        overflow: visible;
        width: 100%;
        height: 100%;

        *{ height: 100%; }
      }

      .teamgrid{
        grid-template-columns: 1fr;
        margin-top: 0;

        .item{

          &:hover{

            .details{
              opacity: 1;
            }
          }

        }
      }

    }

    .slick-list{
      padding-left: 20%;
      padding-right: 10px;

      .slick-track{
        height: 100%!important;
        width: 100%;
        object-fit: contain;

        .item{
          transform: scale(0.95);
          border-radius: 5px;
          overflow: hidden;

          opacity: 0.2;
          transition: opacity 0.4s ease;
        }

        .slick-active{
          .item{
            opacity: 1;
            transition: opacity 0.4s ease;
          }
        }

        *{ height: 100%; }
        img{ height: 100%!important; }
      }
    }

    .grid-toggler{
      display: none;
    }

    .navigation{
      transform: rotate(45deg) translateY(50%);
      left: calc(35% / 2); // 50% of the extra space ($slider-width-tablet) from the slider
      top: 80%;
      height: 50px;
      transform-origin: bottom left;

      h4:nth-child(1){
        top: 100%;
        transform: translate(-180%, -140%) rotate(-45deg);
      }
      h4:nth-child(2){
        bottom: 100%;
        transform: translate(140%, 140%) rotate(-45deg);
      }
    }
  }
}

@media screen and (max-width: $screen-m){
  .teamcontainer{

    .descriptioncontainer{
      top: 30%;
    }
    .portraits{ height: 60vh; }

  }
}

@media screen and (max-width: $screen-s2){
  .teamcontainer{

    .navigation{
      left: 60px;
      top: calc(100% - 110px);

      display: none;

      #chevrons{
        display: none;
      }
    }

    #mobile-humans-navigator{
      display: grid;

      #mobileaboutthishuman{ display: block; }
    }

    .descriptioncontainer{
      display: none;
    }

    .slick-list{
      padding-left: 10%;
      padding-right: 10%;
    }

    .portraits{
      width: 100%;
      height: 65vh;
      min-height: 320px;

      .photos{
        .item{
          opacity: 1;

          pointer-events: none;

          .descriptioncontainer{
            display: block;
          }

          img:hover{ opacity: 0.6; }

        }
      }
    }
  }
}


@media screen and (max-height: $screen-s2){
  .teamcontainer{
    .navigation{
      display: none!important;
    }
  }
}

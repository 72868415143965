#http404{
  position: relative;
  opacity: 0;

  max-width: 1600px;
  margin: auto;

  h1{
    font-size: 100px;
    font-family: Poppins;
    font-weight: 800;
    text-align: center;

    margin: 0;
    padding-top: 10%;
    color: white;
    opacity: 1;

    position: relative;
    width: fit-content;
    margin: auto;

    &::before{
      content: '';
      position: absolute;
      right: -20px;
      top: calc(100% - 50px);
      width: 15px;
      height: 15px;
      background-color: #e8bf2f;
      border-radius: 50%;
    }

    &.no-dot::before{ display: none; }
  }

  img{
    display: block;
    width: 100%;

    top: 60vh;
    transform: none;
    position: absolute;
    transform: translateY(-10vh);
    margin: 0;
    z-index: -1;
  }

  .content{

    width: 500px;
    max-width: 100vw;
    margin: auto;
    text-align: center;

    *{
      color: white;
      margin: 5px 0;
    }

    h3{
      font-size: 24px;
      font-weight: 400;
      margin: 0;
    }

    h4{
      font-size: 16px;
      font-weight: 400;
      opacity: 0.8;
    }

  }
}

#browserunsupported{
  position: relative;

  opacity: 0;
  margin-top: 20px;

  h1{
    font-size: 80px;
    line-height: 80px;
    font-family: Poppins;
    font-weight: 600;
    text-align: center;

    margin: 0;
    color: white;
    opacity: 0.1;

    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }

  img{
    filter: invert(1);
    display: block;
    width: 125px;

    position: absolute;
    left: 50%;
    top: 240px;
    transform: translate(-50%, 0);
  }

  .content{

    width: 500px;
    max-width: 100vw;
    margin: auto;
    text-align: center;

    position: absolute;
    top: 450px;
    left: 50%;
    transform: translate(-50%, -50%);

    *{
      color: white;
      margin: 5px 0;
    }

    h2{
      font-size: 45px;
      line-height: 45px;
      font-family: Poppins;
    }

    h4{
      font-size: 18px;
      font-weight: 400;
      opacity: 0.8;

      width: 350px;
      max-width: 100%;
      margin: auto;
      margin-top: 20px;
    }

  }
}

@media screen and (max-width: $screen-l){
  #http404{
    h1{ padding-top: 20%; }
  }
}

@media screen and (max-width: $screen-s){
  #http404{
    h1{
      padding-top: 40%;
      font-size: 80px!important;
    }

    h3{ font-size: 1rem!important; }
    // img{ top: 65vh; }
  }
}

@keyframes ghostMovement {
  0% { transform: translate(-50%, 0); }
  50% { transform: translate(-50%, -10px); }
  100% { transform: translate(-50%, 0); }
}

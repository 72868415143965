.careerscontainer{
  padding-top: 16vh;
  color: white;

  display: grid;
  grid-template-columns: 0.45fr 0.55fr;

  opacity: 0;

  a{
    text-decoration: none;
    display: table;
  }

  h1{
    font-size: 3rem;
    margin: 10%;
    margin-left: 20%;
  }

  #careerscontent{

    p{
      padding-top: 40px;
      padding-right: 15%;
      font-size: 1rem;
      line-height: 2rem;
      max-width: 600px;
    }

    #postings{
      margin-top: 100px;
      padding-bottom: 60px;

      .posting{
        margin-bottom: 20px;
      }

      h3{
        font-size: 1.4rem;
        font-weight: 400;
        margin: 0;
      }

      h4{
        font-size: 0.8rem;
        font-weight: 400;
      }

      .details{
        padding: 10px 35px;
        background-color: $primary;
        color: $secondary;
        display: table;
        font-size: 0.8rem;
        font-weight: 600;

        cursor: pointer;
        user-select: none;
        transition: color 0.2s ease;

        &:hover{
          color: white;
        }
      }
    }

  }
}

@media screen and (max-width: 1000px){
  .careerscontainer{
    grid-template-columns: 1fr;
    padding-top: 100px;

    h1{
      text-align: center;
      margin: 60px auto;
    }

    #careerscontent{

      padding: 0 10%;

      a{
        margin: auto;
      }

      p{
        padding-top: 0;
        padding-right: 0;
        padding: 0 10%;
        text-align: center;
      }

      #postings{
        display: flex;
        align-items: center;
        flex-direction: column;

        .posting{

          h3{ text-align: center; }
          h4{ text-align: center; }
          .details{ margin: auto; }
        }
      }
    }
  }
}
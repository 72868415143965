.workcontainer{

  position: relative;

  #workscrollbar{
    position: fixed;
    top: 100vh;
    transform: translateY(-100%);

    width: 100%;
    height: 5px;
    background-color: $textgrey;
    // background-color: #565656;

    opacity: 0;
    z-index: 1;
    transition: opacity 0.4s ease;

    &.visible{
      opacity: 1;
    }
  }

  #workcursor{
    position: fixed;
    transform: translate(-50%,-50%) scale(0);
    $cursorwidth: 10px;
    width: $cursorwidth;
    height: $cursorwidth;
    border-radius: 50%;
    background-color: $secondary;

    z-index: 10;
    pointer-events: none;

    &.lock{
      transform: translate(-50%,-50%)!important;
    }
  }

  .main{
    width: 100%;
    height: 100vh;

    position: relative;
    z-index: 1;

    h1{
      font-family: Poppins;
      font-weight: 400;
      font-size: 1.8rem;

      width: fit-content;
      color: white;

      position: absolute;
      right: 120px;
      top: 50vh;

      transform: translateY(calc(-50% + 20px));
      opacity: 0;
    }

    .openprojects{
      font-family: Poppins;
      font-weight: 100;
      font-size: 0.85rem;
      letter-spacing: 1px;

      color: black;
      width: fit-content;
      padding: 10px 15px;

      position: absolute;
      right: 80px;
      top: 70vh;
      transform: rotate(90deg);
      transition: color 0.2s ease;

      cursor: pointer;

      &:hover{
        color: white;
      }

      &::before{
        content: '';
        position: absolute;
        left: 110%;
        top: 50%;
        transform: translateY(-50%);

        width: 0;
        height: 2px;

        background-color: white;

        transition: width 1s ease 0.6s;
      }

      &::after{
        content: '';
        background-color: $primary;
        width: 0;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        transition: width 0.6s ease 0.2s;
      }

      &.inverted::before{
        background-color: $secondary;
      }

      &.active{
        &::before{ width: 50vh; }
        &::after{ width: 100%; }
      }
    }

    #scene{
      $scene-offsetY: 20%;

      margin: auto;
      width: 70vw;
      top: 32%;
      opacity: 0.25;

      .sceneimg{
        &:nth-child(2){
          margin-top: 65px;
          margin-left: 60px;
        }

        img{ max-width: 25vw; }

      }
    }

    .workscenereplacement{
      position: absolute;
      left: 10%;
      top: 50%;
      transform: translateY(-50%);

      max-width: 80%;
      width: 500px;
      z-index: -1;

      display: none;

      img{
        width: 100%;
        filter: brightness(1.2);
      }
    }
  }

  .personalmessage{
    color: rgba(255,255,255,0.8);
    text-align: center;

    position: absolute;
    top: 50vh;
    left: 50%;
    transform: translate(-50%, -50%);

    font-size: 1.25rem;
    font-family: Poppins;
    padding: 14px 25px;
    padding-top: 16px;

    opacity: 0;
  }

  .backproject{
    position: absolute;
    right: 0;
    top: 0;
    margin: 40px;
    color: white;
    opacity: 0.8;
    z-index: 100;
    cursor: pointer;

    opacity: 0;

  }
}

@media screen and (max-width: $screen-l){
  .work{
    .main{
      position: relative;

      h1{
        right: 50%;
        transform: translate(50%, -50%);

        font-family: Poppins;
      }

      .openprojects{
        bottom: unset;
        top: -280px;
      }
    }

  }
}

@media screen and (max-width: $screen-m){
  .workcontainer{
    .main{
      h1{
        left: 50%;
        transform: translate(-50%, -50px)!important;
      }
      .openprojects{
        right: 20px;
      }
    }
  }
}


@media screen and (max-height: $screen-s){
  .workcontainer{
    .main{
      h1{
        left: 50%;
        transform: translate(-50%, -50px)!important;
      }

      .openprojects{
        right: 0;
      }
    }
  }
}


@media screen and (min-width: 1900px){
  .workcontainer{
    .main{

      max-width: 2200px;

      h1{
        font-size: 2rem;
      }

      .workscenereplacement{
        left: 34%;
      }
    }
  }

  .projectlist{
    .content{
      max-width: 1500px;
      margin: auto;

      .project{
        max-width: 1200px!important;

        &.horizontal{
          .descriptions{
            margin-left: 120px;
          }
        }
      }
    }
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "MyriadPro";
  src: local("MyriadPro"),
   url("./fonts/MyriadPro-Regular.otf") format("truetype");
 }

 @font-face {
  font-family: "SFPro";
  src: local("SFPro"),
   url("./fonts/SF-Pro.ttf") format("truetype");
 }

 @font-face {
  font-family: "HanodedBumper";
  src: local("HanodedBumper"),
   url("./fonts/Hanoded-Bumper-Sticker-Regular.otf") format("truetype");
 }

 @font-face {
  font-family: "MarkPro";
  src: local("MarkPro"),
   url("./fonts/Mark-Pro-Medium.otf") format("truetype");
 }


@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,300i,400,600,700|Source+Sans+Pro:300,300i,400,600&display=swap');

$primary: #e8bf2f;
$secondary: #111111;
$darkgrey: #333333;
$textgrey: #555555;
$grey: #989898;

// These widths must match constants.js
$screen-xxl: 1400px;
$screen-xl: 1200px;
$screen-l: 1070px;
$screen-m: 740px;
$screen-s: 500px;
$screen-s2: 600px;

::selection{ background-color: $grey; }

html,body{
  background-color: $secondary;
}

::-webkit-scrollbar { display: none; }

#root {
  transition: opacity 800ms;
}

#hc-logo{
  position: absolute;
  width: 45px;
  height: 20px;
  margin: 45px;
  z-index: 100;
  overflow: hidden;

  img{
    width: 100%;
    height: 20px;
    object-fit: contain;
    display: block;
    transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.6s;
  }

  &.dark{
    img{ transform: translateY(-100%); }
  }
}

.backgroundscene{
  pointer-events: none;
  position: absolute;
  transform: scale(1);
  background: $secondary;
}

.backgroundcover-left,
.backgroundcover-right{
  width: 0%;
  height: 100vh;
  position: absolute;

  background-color: white;

  &.backgroundcover-left{ left: 0; }
  &.backgroundcover-right{ right: 0; }
}

#content{
  z-index: 10;
  position: absolute;
  width: 100vw;

}

.menus{

  *{ user-select: none; }

  #menuIndicator{
    position: absolute;
    left: 20px;
    top: 64px;
    width: 58px;
    height: 6px;
    background-color: $primary;

    opacity: 1;
    width: 0;

    z-index: 11;

  }

  .menu{

    z-index: 11;
    color: black;

    position: absolute;
    left: 50%;
    top: 60px;
    transform: translate(-50%, -50%);

    color: white;
    // mix-blend-mode: difference;
    mix-blend-mode: normal;

    &.edge{ color: grey!important; }

    a, button{
      text-decoration: none;
      color: inherit;
      cursor: default;

      transition: transform 0.2s ease;  

      &:hover{ transform: scale(1.1); }
    }

    button {
      -moz-appearance: none;
      -webkit-appearance: none;
      background: none;
      border: none;
      outline: none;
      font-size: inherit;
      &:focus, span:focus {
        outline: none;
      }
      &:focus span{
        position: relative;
        bottom: 1px;
        outline: -webkit-focus-ring-color auto 1px;
      }
    }

    button {
      -moz-appearance: none;
      -webkit-appearance: none;
      background: none;
      border: none;
      outline: none;
      font-size: inherit;
      &:focus, span:focus {
        outline: none;
      }
      &:focus span{
        position: relative;
        bottom: 1px;
        outline: -webkit-focus-ring-color auto 1px;
      }
    }

    ul{
      display: flex;
      padding: 0;
      transition: opacity 0.2s ease;

      li{
        list-style: none;
        margin: 0px 20px;
        font-size: 18px;
        font-family: 'Source Sans Pro';
        position: relative;
        transition: opacity 0.2s ease;
        white-space: nowrap;

        cursor: default;

        box-sizing: content-box;
      }

      li.language-toggle {
        color: rgb(197, 197, 197);
        top: -5px;
      }
      li #merge-logo {
        width: 50px;
        position: relative;
        top: 0px;
        height: 26px;
      }
      li #merge-logo {
        width: 50px;
        position: relative;
        top: 0px;
        height: 26px;
      }
    }
  }

  .burger{
    $icon-size: 30px;

    display: flex;
    display: none;
    padding: 0 10px;

    position: absolute;
    right: 4%;
    top: 45px;
    z-index: 100;

    mix-blend-mode: exclusion;
    transition: transform 0.2s ease;

    cursor: default;
    user-select: none;

    .icon{
      width: $icon-size;
      height: $icon-size;
      position: relative;
      pointer-events: none;

      transform: translateY(2px);

      &::before,
      &::after{
        content: '';
        width: 60%;
        height: 1px;
        background-color: white;
        mix-blend-mode: exclusion;

        position: absolute;
        transform: translate(0px, 10px);
        z-index: 1;
      }

      &::before{
        top: 0
      }

      &::after{
        top: 6px;
      }
    }

    h4{
      mix-blend-mode: exclusion;
      color: white;
      margin: 0;
      pointer-events: none;

      font-size: 15px;
      font-family: Poppins;
      font-weight: 300;
      align-self: center;
      letter-spacing: 1px;

      position: relative;
      z-index: 100;
    }
  }

  .menu-full{
    background-color: #222222;
    position: absolute;
    left: -100vw;
    top: 0;
    z-index: 100;
    width: 100vw;
    height: 100vh;

    display: grid;
    display: none;
    grid-template-columns: auto 230px;

    #content{

      flex: 1;
      min-height: 100vh;

      #back{
        color: white;
        width: fit-content;
        margin-left: auto;
        margin-top: 10px;
        padding: 25px;
        font-size: 1rem;
        opacity: 0;
        cursor: pointer;

        transition: opacity 0.2s ease;

        &:hover{
          opacity: 1!important;
        }
      }

      ul{
        max-width: 400px;
        margin: 0 8%;

        list-style: none;
        line-height: 5rem;

        font-size: 3.5rem;
        text-align: center;

        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        opacity: 0;

        a, button{
          text-decoration: none;
          text-transform: capitalize;
          all: unset;
        }

        li{
          color: white;
          font-family: Source Sans Pro;
          font-weight: 100;
          opacity: 0.25;
          transition: opacity 0.2s ease;
          cursor: pointer;

          &.active{ opacity: 1; }
        }
        #merge-logo {
          height: 75px;
          position: relative;
          top: 14px;
        }
      }

      #location{
        position: absolute;
        bottom: 40px;
        right: 50px;

        color: white;
        font-size: 1rem;
        letter-spacing: 1px;

        opacity: 0;
        transform: translateY(20px);

        display: none; // or just remove html container

        h4{
          margin: 0;
          font-family: Source Sans Pro;
          font-weight: 300;

          &:first-child{ margin-bottom: 10px; }
        }
      }
    }

    #map{
      opacity: 0.13;
      margin-left: auto;
      position: absolute;
      right: 0;
      overflow: hidden;

      width: 160px;
      height: 100vh;

      #cover{
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: #222222;
        z-index: 1;
      }

      #mapscene{
        height: 100%;
      }

      img{
        margin-top: -20%;
        margin-left: -20%;
        width: 140%;
        height: 140%;
        object-fit: cover;
      }
    }
  }
}

@import './cursor';
@import './loader';
@import './landing';
@import './about';
@import './work';
@import './projects';
@import './projectdetail';
@import './team';
@import './careers';
@import './contact';
@import './text-page';
@import './http404';

@media screen and (max-width: $screen-l){
  .menus{
    #menuitems{
      display: none;
    }

    .menu-full{ display: grid; }
    .menu-full.french-menu-full #content ul{ font-size: 2.5rem; }
    .burger{
      display: flex;
      top: 35px;
    }
  }
}

@media screen and (max-width: $screen-s){
  .menus{
    .menu-full{
      #map{ display: none; }

      #content{
        ul{
          text-align: left;
          font-size: 50px;
          line-height: 4rem;
          top: 45%;
          li #merge-logo {
            position: relative;
            top: 0.4rem;
            left: 3px;
          }
        }
      }
    }

    #hc-logo{
      margin: 40px;
      z-index: 101;
    }
  }
}

@media screen and (max-height: $screen-s2){
  .menus{
    .menu-full{
      #content{
        ul{
          li{
            font-size: 30px;
            line-height: 40px;
          }
          #merge-logo {
            height: 25px;
            position: relative;
            top: 7px;
            left: 0px;
          }
        }
      }
    }
  }
}

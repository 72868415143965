.contactcontainer{

  width: 100%;
  height: 100%;
  min-height: 100vh;

  background-color: $secondary;

  #social{
    display: flex;
    position: absolute;
    right: 10px;
    bottom: 10px;
    z-index: 1;
    opacity: 0;

    div{
      margin: 5px 8px;
      opacity: 0.2;
      transition: opacity 0.1s ease;

      &:hover{ opacity: 0.5; }

      svg,
      img{
        $social-icon-size: 25px;
        width: $social-icon-size;
        height: $social-icon-size;
        pointer-events: none;
        display: block;
      }

      #instagram{ transform: translateY(1px); }

    }
  }

  .container{
    height: 100%;
    color: white;

    .mapcover{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      min-height: 100vh;

      z-index: 1;

      background-color: rgba(5,5,5,0.5);
      backdrop-filter: blur(20px);

      pointer-events: none;
    }

    .map{
      position: absolute;
      width: 40%;
      height: 100%;
      right: 0;
      background-repeat: no-repeat;
      background-size: cover;

      opacity: 0;
      transition: width 0.6s ease;

      .gm-fullscreen-control,
      .gmnoprint,
      .gm-style-cc,
      a[rel="noopener"] img
      { opacity: 0.05; }
    }

    .logo{
      width: 200px;
      height: fit-content;
      margin: 50px;
      margin-left: auto;
      position: relative;
      z-index: 2;

      img{
        width: 200px;
      }
    }

    .form{
      position: absolute;
      top: 50%;
      left: 30%;
      transform: translate(-50%, -80%);

      z-index: 2;
      width: 50%;
      margin-left: 0;
      text-align: left;

      transition: all 0.4s ease;

      *{ z-index: 2; }

      // animations
      h1,h5,.inputs{ opacity: 0; }
      h1, h5{ transform: translateY(20px); }
      // ----------

      h1{
        word-break: break-word;

        font-family: 'Poppins';
        font-weight: 200;
        font-size: 2rem;

        margin-bottom: 0px;
      }

      h5{
        margin-bottom: 20px;
        word-break: break-word;
        margin: 10px 0;
        color: rgba(255,255,255,0.8);

        font-family: Source Sans Pro;
        font-weight: 100;
        font-size: 1rem;
      }

      .inputs{
        position: relative;
        height: 50px;
        // width: 560px;
        width: 0%;
        max-width: 100%;

        input,
        textarea{
          background-color: transparent;
          outline: none;
          border: none;
          border-radius: 0;
          border-bottom: 1px solid white;

          padding: 10px 0;
          width: 100%;

          caret-color: rgba(232,191,47,0.75);
          color: white;
          font-family: 'Poppins';
          font-weight: 100;
          font-size: 1.1rem;

          position: absolute;
        }
      }
      .buttons,
      .errors{
        display: flex;
        width: fit-content;
        margin-left: auto;
        margin-top: 10px;

        position: absolute;
        right: 0;
        bottom: unset;
        top: 50%;
        transform: translateY(100%);

        .send{
          font-size: 20px;
          position: relative;
          cursor: pointer;
        }

        div{
          display: flex;

          div{
            padding: 5px;
            font-family: 'Source Sans Pro';
            position: relative;
            cursor: pointer;
          }

          .send{
            font-size: 20px;
            position: relative;
            cursor: pointer;
          }

        }

        &.buttons{

          opacity: 0;
          transform: translate(-10px, 100%);

          #left{
            img{ transform: rotate(180deg); }
            .line{ transform: translateX(-4px); }
          }
          #right{
            .line{ transform: translateX(4px); }
          }

          .line{
            height: 1px;
            width: 40px;
            background-color: white;

            padding: 0;
            margin: auto;
          }
        }
      }
      .errors{
        margin-left: 0;
        left: 0;
        font-family: BrandonGrotesqueMediumItalic;
        font-size: 14px;
        color: #cf2e2e;

        h4{
          padding-top: 5px;
        }
      }

      .leaf-canvas{
        position: relative;
        z-index: 1;
        overflow: hidden;

        display: none;

        canvas{
          transform: translateY(-40px);
        }
      }

    }

    .info{
      position: absolute;
      bottom: 0;
      left: 20%;
      transform: translateX(-50%);

      width: 30%;
      padding: 40px;

      font-size: 12px;
      font-weight: 100;
      font-family: 'Poppins';
      font-weight: 300;
      line-height: 18px;
      letter-spacing: 1px;
      text-align: left;

      &:last-child{ margin-bottom: 0; }

      h3, a{
        color: rgba(152,152,152,0.6);
        text-decoration: none;
        font-size: 0.8rem;
        margin: 5px 0;
        font-family: Poppins;
        font-weight: 400;
      }

      #cities{
        display: flex;
        opacity: 0.8;
        transition: opacity 0.2s ease;

        h2{
          font-size: 1rem;
          font-weight: 400;
          margin-right: 1rem;
          position: relative;

          cursor: default;

          &::before{
            content: '';
            position: absolute;
            bottom: 0px;
            height: 6px;
            width: 0;
            background-color: $primary;
            z-index: -1;
            transition: width 0.2s ease;
          }

          &.active::before{
            width: 100%;
            transition: width 0.2s ease 0.1s;
          }
        }
      }

      #cities, #details h3{
        opacity: 0;
        transform: translateY(40px);

        cursor: pointer;

        &.active a{ color: rgba(152,152,152,1) }

        &.two a:hover{
          color: rgba(152,152,152,1);
        }
      }

      #terms-and-privacy{
        margin-top: 20px;
      }
    }

  }
}


@media screen and (min-width: $screen-xl){
  .contactcontainer{
    .container{
      .map{
        width: 50%;
      }

      .form{
        width: calc(30% + 5%);
        left: 10%;
        transform: translateY(-80%);

        .leaf-canvas{
          display: none!important;
        }
      }

      .info{
        width: 25%;
        margin-left: 2.5%;
      }
    }
  }
}

@media screen and (max-width: $screen-l){
  .contactcontainer{
    .container{

      .map{
        height: 400px;
        width: 20%;
      }

      .form{
        left: 10%;
        transform: translate(0, -80%);
      }

      .info:last-child{
        width: 80%;
        transform: none;
        padding: 0;
        left: 10%;
        bottom: 40px;
      }

    }
  }
}

@media screen and (max-width: $screen-m){
  .contactcontainer{
    .container{
      .map{
        width: 0;
      }

      .form{
        width: 80%;
      }
    }
  }
}


@media screen and (max-height: $screen-s){
  .contactcontainer.ismobile{
    .container{
      .info{
        left: 15%!important;

        #cities{
          margin-left: auto;
          width: 140px;

          h2{
            font-size: 0.7rem;
            margin-right: 0;
            margin-left: 1rem;
          }
        }

        #details{

          h3, h3 a{
            // text-align: right;
            font-size: 0.6rem;
            line-height: 0.7rem;
          }
        }
      }

      .form{
        transform: none;
        position: relative;
        top: 100px;
      }
    }
  }
}

@media screen and (max-height: $screen-m){
  .contactcontainer.ismobile{
    height: auto;

    .container{
      .form{
        position: relative!important;
        transform: none;
        top: 0;
        margin-top: 160px;
      }

      .info{
        position: unset;
        margin: auto;
        margin-top: 80px;
      }
    }
  }
}

.apploaded #landingcover{
  backdrop-filter: blur(0)!important;
  background-color: rgba(0,0,0,0.2)!important;
}

#landingbackground{
  $image-offset: 20px;

  width: 100vw;
  height: 100vh;
  overflow: hidden;

  video{
    min-width: 100%;
  }

  #landingcover{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    backdrop-filter: blur(10px);
    transition: all 2s ease;
  }

  #landingcover.loaded{
    backdrop-filter: blur(0);
    background-color: rgba(0,0,0,0.2);
  }

  #img-background{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  #img-logo{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 38%;
    height: 100%;
    object-fit: contain;

    pointer-events: none;

    min-width: 240px;
  }
}

#aboutcontainer{
  display: grid;
  grid-template-columns: 0.55fr 0.45fr;
  min-height: 100vh;

  #aboutcontent{
    height: 100%;
    width: 100%;
    background-color: white;
    display: grid;
    align-items: center;
    position: relative;

    opacity: 0;
    animation: fadeIn 0.5s forwards 0.8s;

    #description{
      padding: 0 12%;
      margin: auto;
      align-self: center;
      font-size: 1.1rem;
      line-height: 1.8rem;

      opacity: 0;
    }

    #logos{
      padding: 0 12%;

      #logoscontainer{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-gap: 25px;
        align-items: center;

        .logo{

          width: 80%;
          margin: auto;

          transform: translateY(10px);
          opacity: 0;

          transition: transform 0.2s ease;

          &:hover{
            transform: scale(1.15)!important;
          }
        }

        img{
          width: 100%;
          object-fit: contain;
        }
      }

    }

    #aboutnavigation{
      display: flex;
      position: absolute;
      bottom: 0;
      width: 100%;

      #line{
        width: 0%;
        height: 1px;
        background-color: #111111;
        display: flex;
        margin: auto 20px;
        margin-left: auto;

        animation: lineStretch 0.8s forwards 0.8s;
      }
      #options{
        display: flex;
        margin-right: 35px;
        h4{

          font-family: Poppins;
          position: relative;
          text-align: right;
          width: fit-content;
          cursor: pointer;

          &:nth-child(2){ margin-left: 20px; }

          &::before{
            content: attr(data-index);
            position: absolute;
            top: 0;
            right: 0;
            transform: translate(0%, -100%);
            font-size: 0.6rem;
          }

          &:hover{
            opacity: 0.2;
          }

        }
      }
    }
  }

  #slogan{
    height: 100%;
    background-color: $secondary;
    overflow: hidden;

    video{
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    h1{
      text-transform: uppercase;
      font-family: Poppins;
      font-weight: 600;
      font-size: 10vh;
      line-height: 10vh;

      color: rgba(255,255,255,0.25);
      mix-blend-mode: screen;
      // max-width: 560px;
      padding-right: 40px;

      position: absolute;
      top: 50%;
      transform: translate(10px, -50%);
      margin: 0;
    }
  }
}

@media screen and (max-width: 1200px){
  #aboutcontainer{
    #aboutcontent{
      #logos{
        #logoscontainer{
          grid-template-columns: 1fr 1fr 1fr 1fr;

          .logo{
            width: 70%;
          }
        }
      }
    }

    #slogan{
      h1{
        font-size: 10vh;
        line-height: 10vh;
        max-width: 480px;
      }
    }
  }
}

@media screen and (max-width: 1000px){
  #aboutcontainer{
    #aboutcontent{
      #logos{
        #logoscontainer{
          img{
            width: 60px;
          }
        }
      }

      #aboutnavigation{
        #line{
          width: 200px;
          transition: width 0.2s ease;
        }
      }
    }
  }
}

@media screen and (max-width: 860px){
  #aboutcontainer{
    display: flex;
    flex-direction: column-reverse;
    min-height: unset;

    #slogan{
      width: 100%;
      position: relative;
      min-height: 600px;
      h1{
        position: absolute;
        left: 5%;
        top: 50%;
        transform: translateY(-50%);

          font-size: 8vw;
          line-height: 8vw;
      }
    }

    #aboutcontent{
      min-height: 100vh;
      grid-template-rows: 1fr auto;

      #logos{
        #logoscontainer{
          grid-template-columns: 1fr 1fr 1fr 1fr;

          .logo{
            width: 50%;
          }
        }
      }

      #aboutnavigation{
        position: unset;

        #line{
          display: none;
        }
        #options{
          margin: auto;
          h4{ font-size: 2rem; }
        }
      }
    }
  }
}

@media screen and (max-width: 700px){
  #aboutcontainer{
    #aboutcontent{
      #logos{
        #logoscontainer{
          grid-template-columns: 1fr 1fr 1fr;
        }
      }
    }
  }
}

@media screen and (max-height: 700px){
  #logoscontainer{
    .logo{
      max-height: 60px;
    }
  }
}
@media screen and (min-height: 850px){
  #aboutcontainer{
    #aboutcontent{
      #aboutnavigation{
        #options{
          h4{ font-size: 1.5rem; }
        }
      }

      #description{
        font-size: 1.4rem;
      }
    }
  }
}

@keyframes fadeIn{
  0%{ opacity: 0; }
  100%{ opacity: 1; }
}
@keyframes lineStretch{
  0%{ width: 0%; }
  100%{ width: 50%; }
}

$cursor-width: 35px;
$cursor-width-active: 28px;

.cursor{

  &.main{
    position: absolute;
    transform: translate(-50%,-50%);
    width: $cursor-width;
    height: $cursor-width;
    border-radius: 50%;
    border: 2px solid $darkgrey;
    mix-blend-mode: exclusion;

    opacity: 0;

    z-index: 100;
    pointer-events: none;

    transition: all 0.2s ease;
    transition-property: width, height, opacity;
  }

  &.progress{
    width: 100%;
    height: 100%;
    border-radius: 50%;

    stroke: white;
    stroke-width: 2px;
    stroke-dasharray: 1000; // start: 1055 | end: 1000
    stroke-dashoffset: 2000;
    fill: transparent;
    transition: stroke-dasharray 0.2s ease;

    transform: rotate(180deg);
  }

  &.active{
    // width: $cursor-width-active;
    // height: $cursor-width-active;
  }

  &.hidden{
    opacity: 0;
  }

  // &.mouse{
  //   width: 29px;
  //   height: 50px;
  //   margin-left: -20px;
  //   top: 50%;
  //   margin-top: -35px;
  //   box-shadow: inset 0 0 0 1px #fff;
  //   border-radius: 25px;
  //
  //   position: absolute;
  //   left: 50vw;
  //   transform: translateY(-100%);
  //
  //   &:before {
  //     content: '';
  //     width: 8px;
  //     height: 8px;
  //     background: #fff;
  //     margin-left: 10px;
  //     top: 8px;
  //     border-radius: 4px;
  //     animation-duration: 1.5s;
  //     animation-iteration-count: infinite;
  //     animation-name: scroll;
  //
  //     position: absolute;
  //   }
  // }

  .description{
    position: absolute;
    top: 100%;
    width: 100%;

    color: white;
    font-family: Poppins;
    font-weight: 100;
    text-align: center;

    overflow: visible;
    background-blend-mode: difference;

  }

}

@media screen and (max-width: $screen-m){
  .cursor{
    display: none!important;
  }
}
